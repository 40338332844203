const FIELD_NAME = {
    LANGUAGE: "3D74C390-BA94-11EA-B900-E989F81A2C16",
    COUNTRY: "46210ED0-A979-11EA-A1CA-CD48149BE761",
    FULL_NAME: "F11F45D0-1A39-11ED-9819-B5188091EA18",
    COMPANY_NAME: "6BCC3A80-D622-11EA-AE02-57A132B33678",
    MOBILE_NUMBER: "0FB3EE00-1D51-11ED-9819-B5188091EA18",
    EMAIL: "59B5B030-12A3-11EA-909D-894EE92CA4F3",
    LOGIPULSE_NEW_PASSWORD: "F869AB40-6539-11EE-B504-799720D4801A"
}

module.exports = {
    FIELD_NAME
}