
import { getCountries } from '../graphql/country';
import { Amplify, graphqlOperation, API } from 'aws-amplify';
import { SearchOperationBuilder, SearchParam } from 'wherecomposer/dist/SearchParam';
import { ConditionCreator } from 'wherecomposer/dist/ConditionCreator';

let countryAppsyncUrl = process.env.GATSBY_COUNTRY_APPSYNC_URL;
let countryApiKey = process.env.GATSBY_COUNTRY_API_KEY;

const getCountry = async (languageGuid) => {
    let searchParamArray = [];
    let objSearchParam = new SearchParam();
    objSearchParam.searchParamCreate("LanguageGuid", "Guid", languageGuid, SearchOperationBuilder.create(true, false, false));
    searchParamArray.push(objSearchParam);

    let objCreator = new ConditionCreator();
    let objWhere = objCreator.getSearchCondition(searchParamArray);
    let searchParam = objWhere.toJsonString();

    const countryConfig = {
        API: {
            'graphql_endpoint': countryAppsyncUrl,
            graphql_headers: async () => {
                return {
                    'x-api-key': countryApiKey
                };
            }
        }
    }

    Amplify.configure(countryConfig);

    return API.graphql(graphqlOperation(getCountries, { OperationList: searchParam }));
}

export {
    getCountry
}