import React, { useEffect, useState, useCallback, useRef } from "react";
import { Trans, I18nextContext, Link } from 'gatsby-plugin-react-i18next';
import Photo from "../components/Photo";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import "../styles/purchase.scss";
import "../styles/freetrial.scss";
import "../styles/common_style.scss";
import { getCountry } from '../services/country-service';
import Modal from 'react-modal';
import { useTranslation } from "react-i18next";
import {
    getRegisteredUserByEmail, registerTrialUser
} from "../services/tenant-identity-service";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Helmet } from "react-helmet";
import { MESSAGE } from '../constant/message-enum';
import { EventTypeEnum } from '../constant/entity-type-enum';
import { getMessages, getFieldConsoleMessages, getValueMessages } from '../services/language-service';
import * as uuid from "uuid";
import { FIELD_NAME } from '../constant/field-name-enum';
import { navigate } from "gatsby";


const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL;
const GEOLOCATION_URL = process.env.GEOLOCATION_URL;
const ENGLISH_LANGUAGE_GUID = process.env.ENGLISH_LANGUAGE_GUID;

const alertBoxStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        // padding: '48px',
    }
};

const initialFormValue = {
    fullName: "",
    companyName: "",
    countryGuid: uuid.NIL,
    email: "",
    mobileNo: "",
    password: ""
}

function FreeTrial() {
    const { language } = React.useContext(I18nextContext);
    const buildCanonicalUrl = () =>
        language === "en"
            ? GATSBY_LANDING_PAGE_URL + "/free-trial/"

            : GATSBY_LANDING_PAGE_URL + `/${language}/free-trial/`;

    const canonicalUrl = buildCanonicalUrl();

    const buildHrefUrl = () =>
        language === "en"
            ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
            : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + `/${language}/`))
    const hrefUrl = buildHrefUrl();

    const { t } = useTranslation();

    const [alert_message, setAlertMessage] = useState('');
    const [formValues, setformValues] = useState(initialFormValue);
    const [isLoading, setLoading] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [isLoadingCountry, setCountryLoading] = useState(false);
    const [isAlertModalOpen, setAlertModelOpen] = useState(false);
    const [geoLocationCountryCode, setGeoLocationCountryCode] = useState("");
    const [geoLocationCountryGuid, setGeoLocationCountryGuid] = useState(uuid.NIL);
    const [selected_dial_code, setDialCode] = useState("");
    const [isTrialDataSubmitted, setTrialDataSubmitted] = useState(false);
    const [showEyeOption, setShowEyeOption] = useState(false);
    const [passwordType, setPasswordType] = useState('password');
    const [showPassword, setShowPassword] = useState(false);
    const fullNameRef = useRef(null);

    const handleAlertModalClose = async (event) => {
        setAlertModelOpen(false);
    }

    const initializeFormValues = () => {
        let formValue = JSON.parse(JSON.stringify(initialFormValue));
        formValue.mobileNo = geoLocationCountryCode;
        formValue.countryGuid = geoLocationCountryGuid;
        setformValues(formValue);
        setAutoFocusForFullName();
    }

    const getLanguageGuidByCode = async (currentLanguageCode) => {
        let languageGuid = ENGLISH_LANGUAGE_GUID;
        let langFromLocalStore = JSON.parse(localStorage.getItem('languages'));

        if (langFromLocalStore !== undefined && langFromLocalStore.length > 0) {
            languageGuid = langFromLocalStore.find(language => language.Code === currentLanguageCode).LanguageId;
        }

        return languageGuid;
    }

    const getCountries = useCallback(async () => {
        let countryResult = [];

        try {
            setCountryLoading(true);
            let currentLanguageCode = `${language}`;
            let languageGuid = await getLanguageGuidByCode(currentLanguageCode);
            let response = await getCountryData(languageGuid);
            countryResult = response.data.getCountries.map(value => {
                return { value: value.GroupGuid, display: value.Name, isoCode: value.ISOCode }
            });
            countryResult.sort((a, b) => (a.display > b.display) ? 1 : -1);
            setCountryList(countryResult);
            setCountryLoading(false);
            return countryResult;
        } catch (error) {
            setCountryLoading(false);
        }
    }, [language]);

    useEffect(() => {
        let isApiSubscribed = true;
        let countryList = [];
        initializeFormValues();

        getCountries()
            .then(async (res) => {
                countryList = res;
                fetch(GEOLOCATION_URL)
                    .then(data => data.json())
                    .then(json => {
                        if (isApiSubscribed) {
                            let geoLocation = json;
                            if (isApiSubscribed) {

                                let countryCode = geoLocation.country_code;
                                let countryPhoneCode = geoLocation.country_calling_code;
                                let value = { ...initialFormValue };

                                if (countryList.length > 0) {
                                    let selectedCountryList = countryList.filter((obj) => {
                                        return obj.isoCode === countryCode
                                    });

                                    if (selectedCountryList.length > 0) {
                                        let selectedCountryGuid = selectedCountryList[0].value;
                                        setGeoLocationCountryGuid(selectedCountryGuid);
                                        setGeoLocationCountryCode(countryPhoneCode);
                                        setDialCode(countryPhoneCode);
                                        value['countryGuid'] = selectedCountryGuid;
                                    }
                                }

                                value['mobileNo'] = countryPhoneCode;
                                setformValues(value);
                            }
                        }
                    })
                    .catch(err => { });
            }).catch(err => {
            });
        return () => {
            isApiSubscribed = false;
        };
    }, [getCountries]);

    const handleChange = (event) => {
        const fieldName = event.target.name;
        const value = { ...formValues };
        value[event.target.name] = event.target.value;
        setformValues(value);

        if (fieldName === 'password') {
            setShowEyeOption(true);
            setShowPassword(false);
        }
    }

    const clearAlertType = async () => {
        setAlertMessage("");
    }

    const submitHandler = async (event) => {
        event.preventDefault();
        await clearAlertType();
        await submitTrialUserData();
    }

    const validateInputData = async (formValues) => {
        let currentLanguageCode = `${language}`;
        let validEmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let inValidList = [];
        let bCanContinue = true;

        if (formValues.fullName === null || formValues.fullName === "" || formValues.fullName === undefined) {
            inValidList.push(FIELD_NAME.FULL_NAME);
            bCanContinue = false;
        }

        if (formValues.companyName === null || formValues.companyName === "" || formValues.companyName === undefined) {
            inValidList.push(FIELD_NAME.COMPANY_NAME);
            bCanContinue = false;
        }

        if (formValues.email === null || formValues.email === "" || formValues.email === undefined) {
            inValidList.push(FIELD_NAME.EMAIL);
            bCanContinue = false;
        }

        if (formValues.countryGuid === null || formValues.countryGuid === "" || formValues.countryGuid === undefined || formValues.countryGuid === uuid.NIL) {
            inValidList.push(FIELD_NAME.COUNTRY);
            bCanContinue = false;
        }

        if (formValues.mobileNo === null || formValues.mobileNo === "" || formValues.mobileNo === undefined) {
            inValidList.push(FIELD_NAME.MOBILE_NUMBER);
            bCanContinue = false;
        }

        if (formValues.password === null || formValues.password === "" || formValues.password === undefined) {
            inValidList.push(FIELD_NAME.TrialOTPVerification);
            bCanContinue = false;
        }

        if (bCanContinue === false) {
            let validationResponse = await getFieldConsoleMessages(MESSAGE.VALIDATION_EXCEPTION, currentLanguageCode, inValidList);
            setAlertMessage(validationResponse);
            setAlertModelOpen(true);
        }
        else {
            if (validEmailRegex.test(formValues.email) === false) {
                bCanContinue = false;
                let email = formValues.email;
                let valueFields = { V1: email };
                let validationMessage = await getValueMessages(MESSAGE.INVALID_EMAIL_MESSAGE, currentLanguageCode, JSON.stringify(valueFields));
                setAlertMessage(validationMessage);
                setAlertModelOpen(true);
            } else if (formValues.mobileNo === selected_dial_code) {
                bCanContinue = false;
                let validationMessage = await getMessages(MESSAGE.INVALID_MOBILE_NUMBER_MESSAGE, currentLanguageCode);
                setAlertMessage(validationMessage);
                setAlertModelOpen(true);
            }
        }

        return bCanContinue;
    }

    const getCountryData = (languageGuid) => {
        return new Promise((resolve, reject) => {
            getCountry(languageGuid).then(data => {
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    const phoneInputOnChange = (value, data, event, formattedValue) => {
        const formData = { ...formValues };
        formData['mobileNo'] = '+' + value;
        setformValues(formData);
        let selected_dial_code = '+' + data.dialCode;
        setDialCode(selected_dial_code);
    };

    const showPasswordValue = (event) => {
        event.preventDefault();
        setShowPassword(false);
        setPasswordType('password');
    }

    const hidePasswordValue = (event) => {
        event.preventDefault();
        setShowPassword(true);
        setPasswordType('text');
    }

    const submitTrialUserData = async (event) => {
        let currentLanguageCode = `${language}`;
        let employee_id = localStorage.getItem('e_id');
        let e_id = employee_id == null ? 0 : parseInt(employee_id);
        let campaign_id = localStorage.getItem('c_id');
        let c_id = campaign_id == null ? 0 : parseInt(campaign_id);

        let inputData = {
            "fullName": formValues.fullName,
            "companyName": formValues.companyName,
            "email": formValues.email,
            "countryGuid": formValues.countryGuid,
            "phone_number": formValues.mobileNo,
            "entity_type": EventTypeEnum.FREE_TRIAL,
            "empl_id": e_id,
            "campaign_id": c_id,
            "password": formValues.password
        }

        let isValid = await validateInputData(formValues);

        if (isValid) {
            setLoading(true);
            let checkEmailAlreadyRegistered = await getRegisteredUserByEmail(formValues.email);

            if (checkEmailAlreadyRegistered.length > 0) {
                let validationMessage = await getMessages(MESSAGE.EMAIL_ALREADY_REGISTERED, currentLanguageCode);
                setAlertMessage(validationMessage);
                setAlertModelOpen(true);
                setLoading(false);
            } else {
                registerTrialUser(inputData).then(res => {
                    setLoading(false);
                    // setAlertMessage(res.data.returnResponse);
                    // setAlertModelOpen(true);
                    setTrialDataSubmitted(true);
                    navigate("/otp-verification", { state: formValues });
                }).catch(async (err) => {
                    setLoading(false);
                    let errorMessage = err.response.data.errorMessage;
                    if (errorMessage.includes("Value at 'password' failed to satisfy constraint")) {
                        let currentLanguage = `${language}`;
                        let validationMessage = await getMessages(MESSAGE.PASSWORD_POLICY_MESSAGE, currentLanguage);
                        setAlertMessage(validationMessage);
                        setAlertModelOpen(true);
                    } else {
                        setAlertMessage(errorMessage);
                        setAlertModelOpen(true);
                    }
                });
            }
        }
    }

    const setAutoFocusForFullName = () => {
        if (fullNameRef.current) {
            fullNameRef.current.focus();
        }
    }

    return (
        <div>
            <Helmet>
                <title>
                    {t("Logipulse – Free-Trial")}
                </title>
                <Link rel="canonical" href={canonicalUrl} />
                <Link rel="alternate" href={hrefUrl} hreflang={language} />

            </Helmet>
            <main className="purchase-page freetrial_page_main" lang={language}>
                <div className="purchase-page-div freetrial_page_main_div" lang={language}>
                    <div className="container">
                        <div className="row purchase_freetrial freetrial_content_row_div padding_tb_45" lang={language}>
                            <div className="col-md-6">
                                <div className="contents purchasecontents">
                                    <Link to="/" language={language}>{" "}<Photo src="LogiPulse-Logo-H48.svg" className="photo-size" alt="" />{" "}</Link>
                                    <p className="padding_bottom_unset"><Trans>Here starts your novel opportunity to lead the competition.</Trans><Trans>Logipulse renders you a digital transformation to your Logistics operation.</Trans><Trans>Spread out your wings around the world and manage them from a single platform.</Trans><Trans>Head out of adversity!</Trans></p>
                                    <p><span className="credit_card_txt"><b><Trans>CREDIT_CARD_NOT_REQUIRED</Trans></b></span></p>
                                    <Link to="/login/" className="sign-up postion_bottom_17 getsign"><Trans>Sign In</Trans></Link>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="contents">
                                    <div className="purchase_heading_section">
                                        <div className="profile">
                                            <b><Trans>Sign Up</Trans></b>
                                        </div>
                                        <div className="purchase_progress">
                                            <ul id="progressbar_purchase">
                                                <li className="active"></li>
                                                <li></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <fieldset disabled={isLoading}>
                                        <Form onSubmit={submitHandler} autoComplete="off">
                                            <Form.Group controlId="formFullName" >
                                                <Form.Label><Trans>Full Name</Trans><span className="mandatory"><sup>*</sup></span></Form.Label>
                                                <Form.Control type="text" disabled={isTrialDataSubmitted} name='fullName' value={formValues.fullName} ref={fullNameRef}
                                                    onChange={handleChange} required />
                                            </Form.Group>

                                            <Form.Group controlId="formCompany">
                                                <Form.Label><Trans>Company Name</Trans><span className="mandatory"><sup>*</sup></span></Form.Label>
                                                <Form.Control type="text" disabled={isTrialDataSubmitted} name="companyName" value={formValues.companyName}
                                                    onChange={handleChange} required />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="exampleForm.ControlSelect1" lang={language}>
                                                {isLoadingCountry && (
                                                    <Photo src="transparent-loader.png" className="select_control_loader" alt="" />
                                                )}
                                                <Form.Label><Trans>Country</Trans><span className="mandatory"><sup>*</sup></span></Form.Label>
                                                <Form.Control as="select" required name="countryGuid" value={formValues.countryGuid} onChange={handleChange} lang={language}>
                                                    {countryList && countryList.map((elmt) => <option disabled={isTrialDataSubmitted} key={elmt.value} value={elmt.value}>{t(elmt.display)}</option>)}
                                                </Form.Control>
                                            </Form.Group>

                                            <div className="margin_bottom_16">
                                                <Form.Label className="phone_input_label"><Trans>Mobile Number</Trans><span className="mandatory"><sup>*</sup></span></Form.Label>
                                                <PhoneInput
                                                    country={'us'}
                                                    containerClass="my-container-class"
                                                    inputClass="phone_input_class"
                                                    required="true"
                                                    enableSearch="true"
                                                    value={formValues.mobileNo}
                                                    disabled={isTrialDataSubmitted}
                                                    onChange={phoneInputOnChange}
                                                /></div>

                                            <Form.Group controlId="email" >
                                                <Form.Label><Trans>COMPANY_EMAIL</Trans><span className="mandatory"><sup>*</sup></span></Form.Label>
                                                <Form.Control
                                                    type="email" name="email"
                                                    value={formValues.email}
                                                    placeholder={t("Enter your Email")}
                                                    onChange={handleChange} required
                                                    disabled={isTrialDataSubmitted} />
                                            </Form.Group>

                                            <Form.Group controlId="password">
                                                <Form.Label>
                                                    <Trans>LOGIPULSE_NEW_PASSWORD</Trans>
                                                    <span className="mandatory"><sup>*</sup></span>
                                                </Form.Label>
                                                <div className="position_relative">
                                                    <Form.Control
                                                        type={passwordType}
                                                        name="password"
                                                        value={formValues.password}
                                                        required
                                                        onChange={handleChange}
                                                        // className="input_style"
                                                    />
                                                    {
                                                        showEyeOption ? (<div className="eyeposition">
                                                            {
                                                                showPassword ? (<div className="eyeopen" onClick={showPasswordValue}>
                                                                    <Photo
                                                                        src="eye_visibility_on.png"
                                                                        className="width_20"
                                                                        alt="eye_visibility_on"
                                                                    />
                                                                </div>)
                                                                    :
                                                                    (<div className="eyeclose" onClick={hidePasswordValue}>

                                                                        <Photo
                                                                            src="eye_visibility_off.png"
                                                                            className="width_20"
                                                                            alt="eye_visibility_off"
                                                                        />
                                                                    </div>)
                                                            }
                                                        </div>) : null
                                                    }
                                                </div>
                                            </Form.Group>

                                            <div id="main" lang={language}>
                                                <div className="justify_content_flex_end display_flex">
                                                    <Form.Group className="next_button_align">
                                                        <button type="submit" className="trial_submit_button font_12 muller_medium display_flex flex_align_center justify_center pointer position_relative" disabled={isLoading}><Trans>NEXT_BTN</Trans>
                                                            {
                                                                isLoading ? (
                                                                    <Photo src="loader_64.gif" className="spinner" alt="" />
                                                                ) : null
                                                            }
                                                        </button>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </Form>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={isAlertModalOpen}
                    ariaHideApp={false}
                    onRequestClose={handleAlertModalClose}
                    contentLabel="Example Modal In Gatsby"
                    style={alertBoxStyle} className="modal_padding"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <div className="modal-body">
                        <div className="termscondition_content pb-0">{alert_message}</div>
                    </div>
                    <div className="modal-footer text_align_center pl-0 pr-0 mt-0 justify_space_around">
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={handleAlertModalClose}>OK</button>
                    </div>

                </Modal >

                <footer className="purchase-footer">
                    <div className="purchase-footer-bottom">
                        <div className="container">
                            <div className="row footer_row_editor align-content-center">
                                <div className="col-md-6 power">
                                    <div className="powered_by_font_style"><Trans>Powered by</Trans></div>
                                    <Photo src="Stellosysyw-Logo.svg" alt="" className="powered_logo" />
                                </div>
                                <div className="col-md-6 copyright copyright_flex_align">
                                    <div className="copyright_font_style">
                                        <Trans>Copyright 2021 Stellosys</Trans><br /> <Trans>All rights Reserved.</Trans>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </main>
        </div>
    )
}

export default FreeTrial;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
