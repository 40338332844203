export var getCountry = `query getCountries($OperationList:[OperationList]){
    getCountries(OperationList:$OperationList){
      ID
      CountryGuid
      LanguageGuid
      GroupGuid
      CountryCode
      CountryFlag
      Name
      Nationality
      IsActive
      ISOCode
      Timestamp
    }
  }`;

export var getCountries = `query getCountries($OperationList:[OperationList]){
    getCountries(OperationList:$OperationList) {
      Name
      GroupGuid
      CountryCode
      ISOCode
    }
  }`;